<template>
  <new-contact></new-contact>
  <div>
    <div class="projects-container">
      <projects-comp
        v-for="project in projects"
        :project="project"
        v-bind:key="project.title"
      >
        <template v-slot:title>
          {{ project.title }}
        </template>
        <template v-slot:shortDescription>
          {{ project.shortDescription }}
        </template>
      </projects-comp>
    </div>
  </div>
</template>

<script>
import NewContact from "@/components/new_contact/newContact";
import ProjectsComp from "@/components/projectsComp/projectsComp";
import sanity from "../../client";

const query = `*[_type == "project"] | order(title) {title, description, shortDescription, urlTo, coverColor, techStack,
 "image": mainImage {asset->{_id, url }}
 }`;

export default {
  name: "projectViewComp",
  components: { NewContact, ProjectsComp },
  props: ["notMobile"],
  data() {
    return {
      projects: null,
    };
  },
  methods: {
    async fetchData() {
      try {
        this.projects = await sanity.fetch(query);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped lang="scss">
.projects-container {
  background: center repeat url("../../assets/images/SVG/Asset 24.svg");
  background-size: contain;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 40em) {
    background: center no-repeat url("../../assets/images/SVG/Asset 24.svg");
    background-size: contain;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
</style>
