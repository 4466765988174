<template>
  <div class="container" v-cloak>
    <div class="frame-container">
      <div
          @mouseenter="notHovered = false"
          @mouseleave="notHovered = true"
          class="frame-container__cover"
      >
        <h2>
          <slot name="title"></slot>
        </h2>
        <transition mode="out-in">
          <span class="short-description" v-if="notHovered">
            <slot name="shortDescription"></slot>
          </span>
          <div style="display: inline" v-else-if="!notHovered">
            <ul>
              <li v-for="(value, name) in project.techStack" :key="name">
                {{ value }}
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <div v-if="notMobile" class="large-screen"></div>
      <!--      <iframe-->
      <!--        v-if="notMobile"-->
      <!--        :src="project.urlTo"-->
      <!--        :width="frameWidth"-->
      <!--        :height="frameWidth"-->
      <!--      ></iframe>-->
      <div v-else class="empty-frame">
        <img v-if="project.image" :src="project.image.asset.url" alt=""/>
      </div>
      <div class="link-element">
        <a :href="project.urlTo">
          <svg style="width: 33px; height: 33px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M5.64,4.22H19.78V18.36L15.54,14.12L9.88,19.78L4.22,14.12L9.88,8.46L5.64,4.22M17.66,6.34H10.59L12.71,8.46L7.05,14.12L9.88,16.95L15.54,11.29L17.66,13.41V6.34H17.66Z"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
  <br/>
  <br/>
  <br/>
</template>

<script>
export default {
  name: "projectsComp",
  props: ["project"],
  data() {
    return {
      frameInnerWidth: 0,
      notHovered: true,
    };
  },
  mounted() {
    this.frameInnerWidth = Math.ceil((window.innerWidth / 2) * 0.8);
  },
  computed: {
    frameWidth() {
      if (window.innerWidth < 500) {
        return window.innerWidth;
      } else {
        return this.frameInnerWidth;
      }
    },
    myPadding() {
      if (window.innerWidth < 500) {
        return 20;
      } else {
        return (window.innerWidth / 2 - this.frameWidth) / 2;
      }
    },

    notMobile() {
      return 500 < window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}

.container {
  display: flex;
  @media screen and (min-width: 40em) {
    margin: 3rem auto;
  }
}

.large-screen {

  width: 100%;
  @media screen and (min-width: 40em) {

    width: 40vw;
    min-height: 50vh;
    border-radius: 3rem 3rem 0 0;
  }

}

.frame-container {
  position: relative;
  display: flex;

  @media screen and (min-width: 40em) {

    width: 40vw;
    border-radius: 3rem 3rem 0 0;
  }

  @media screen and (min-width: 40em) {
    width: 40vw;
  }

  &__cover {
    position: absolute;
    display: inline;
    top: 0;
    width: 100vw;
    background: var(--color-secondary);
    text-align: center;
    padding-bottom: 2rem;
    box-shadow: var(--shadow-small);
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    font-weight: 500;

    @media screen and (min-width: 40em) {
      background: rgba(252,252,252,0.1);
      border: dashed .6rem white;

      width: 40vw;
      min-height: 20rem;
      border-radius: 3rem 3rem 0 0;
    }

    h2 {
      color: white;
    }

    ul {
      text-align: start;
      font-size: 2rem;
    }
  }

  iframe {
    border: none;

    @media screen and (min-width: 40em) {
    }
  }
}

.empty-frame {
  min-height: 30rem;
  width: 100vw;

  img {
    max-width: 100%;
    height: auto;
  }
}

.link-element {
  padding: 1rem;
  height: 4rem;
  background-color: var(--color-my-white);
  opacity: 0.7;
  position: relative;
  z-index: 1;
  right: -1rem;
  top: 0;
  color: #2c3e50;
  border-radius: 0 3rem 0 0;

}

.short-description {
  font-size: 2rem;
  @media screen and (min-width: 40em) {
  }
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
</style>
