<template>
  <section class="navbar">
    <Navbar></Navbar>
  </section>

  <section class="view-container">
    <project-view-comp ></project-view-comp>
  </section>
</template>

<script>
import ProjectViewComp from "@/components/projectsComp/projectViewComp";
import Navbar from "@/components/navbar";

export default {
  name: "projectsPage",
  components: { ProjectViewComp, Navbar },

};
</script>

<style scoped lang="scss">
</style>
